@import '~@one/web-components/dist/owc/owc.css';
@import '~@one/icons/dist/one-icons-outlined.css';
@import '~@one/icons/dist/one-icons-filled.css';
@import '~@one/roche-font/roche-font.css';
@import '~@one/design-tokens/css/navify/light/variables.css';
// Also you can use cobas light theme, cobas dark theme, navify light theme or navify dark theme
@import '~@one/design-tokens/css/cobas/light/variables.css';
// @import '~@one/design-tokens/css/cobas/dark/variables.css';
// @import '~@one/design-tokens/css/navify/dark/variables.css';

body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

$white: var(--one-color-accent-white);
//colour token variables
$white: var(--one-color-accent-white);
$blue-500: var(--one-color-blue-500);
$gray-500: var(--one-color-gray-500);
$gray-50: var(--one-color-gray-50);
$gray-600-li-n4: var(--one-color-gray-600);
$green-600-sb: var(--one-color-subbrand-600);
$blue-600: var(--one-color-blue-600);
$black-high-contrast: #21201f;
$light-bg-neutral3: #312f2e;
$light-bg-brand-2: #F7F9FC;



//Spinner
.loading-modal-spinner {
  z-index: 9999;
  background: $white;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  text-align: center;
}

.owc-progress-spinner {
  position: relative;
  top: 50%;
}

.dashboard-content {
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }

  .search-result-div {
    padding-bottom: 24px;
  }
  
  .search-loader {
    padding-bottom: 24px;
  
    .owc-skeleton-loader {
      background-color: $gray-50;
    }
  }

  //overview table
  .owc-table {
    .owc-card {
      border: none;
      height: 480px;
    }

    .owc-table-header {
      background-color: $gray-50;
    }

    .owc-table-header-cell {
      min-height: unset;
      padding-top: 3px;
      padding-bottom: 3px;
      justify-content: flex-start;

      .owc-skeleton-loader {
        background-color: #dbd6d1; //token not available in kit
        width: 150px !important;
      }
    }

    //table header label wrapper
    .owc-table-header-cell.owc-table-header-cell--valign-top
    > .owc-table-header-cell__slot-wrapper {
      display: flex;
      max-width: fit-content;
      height: fit-content;
      padding: 5px 12px 5px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .owc-typography {
        color: $gray-600-li-n4;
      }
    }

    //sort arrows
    .owc-table-header-cell
    > .owc-table-header-cell__suffix-wrapper
    > .owc-table-header-cell__suffix-wrapper__sorting-icon {
      margin-left: 0px;
      color: $gray-500;
    }

    .owc-table-cell {
      min-height: 42px;
      padding-top: unset;
      padding-bottom: unset;
      align-items: center;

      .owc-typography {
        color: $gray-500;
        text-align: center;
      }

      .owc-skeleton-loader {
        background-color: $gray-50;
        width: 150px !important;
      }

      //hyperlink in table content
      .owc-hyperlink__anchor__default-slot-wrapper {
        color: $light-bg-neutral3; //color not available as token in owc kit.
        font-weight: 500;

        .hyperlink-div {
          max-width: 430px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    //Expanded Text
    .owc-table-row.owc-table-row--expanded > .owc-table-row__expanded-wrapper {
      color: $gray-600-li-n4;
    }
  }

  //Search box div
  .table-search-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin-top: 32px;
    margin-bottom: 24px;

    .owc-input.owc-input--size-small {
      height: 36px;
      min-height: 36px;
    }

    .owc-input__input-wrapper__prefix-container {
      margin-right: 0px;
      color: $gray-500
    }

    // .owc-button button {
    //   padding-top: 6px;
    //   padding-bottom: 6px;
    //   color: $white;
    // }

    // .owc-icon.owc-icon--not-loaded {
    //   width: 0.2em !important;
    //   color: #000 !important;
    // }
  }
}

.nodata-innerdiv {
  min-width: 450px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-top: 154px;
  margin-bottom: 154px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
      width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
      width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
      width: 1170px;
  }
}

.custom-tooltip {
  padding: 6px;
  display: block;
  z-index: 10000000000000000;
  background: #312f2e;
  color: var(--one-color-accent-white);
  margin: 15px;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 4px;
  white-space: nowrap;
}

.input-margin-top {
  margin-top: 8px;
}

.ngx-file-drop__drop-zone[_ngcontent-ng-c2614662205] {
  height: 100px;
  margin: auto;
  border: 2px solid #FFFFFF !important;
}

@media (hover: hover) {
  .owc-button.owc-button--variant-tertiary.owc-button--purpose-default:hover:not(.owc-button--disabled) {
    background-color: transparent !important;
    // color: blue !important;
  }
}